<template>
  <div class="question">
    <homework-container :question="question"></homework-container>
    <div class="row">
      <div class="col-md-6" v-for="(item, index) in question.questions" :key="index">
        <div class="answer">
          <div class="answer-title">
            <div v-html="$options.filters.formatBrTagText(item.title)"></div>
          </div>
          <div class="answer-detail" v-for="(answer, key) in item.answers" :key="key">
            <el-checkbox-group v-model="checkList[index]" :class="renderClassFill(checkList, index, answer, item)">
              <el-checkbox :label="answer.content">
                <span :class="renderClass(answer, item)">{{ answer.content }}</span>
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//css
import '@/assets/sass/homework/single-choice.scss';
import homeworkContainer from "@/views/pages/classrooms/homework-type/homework-container";
export default {
  name: "multi-choice",
  components: { homeworkContainer },
  props: {
    question: {
      type: Object,
      default: () => { return {} }
    }
  },
  data() {
    return {
      checkList: []
    }
  },
  watch: {
    question: {
      handler() {
        this.question.questions.forEach((question, index) => {
          // let correctAnswers = question.correct_answer.split("##");
          let studentAnswers = question.student_answer.split("##");
          let mergedAnswers = [];
          // correctAnswers.forEach((question) => {
          //   mergedAnswers.push(this.clean(question));
          // })
          studentAnswers.forEach((answer) => {
            if (!mergedAnswers.includes(this.clean(answer))) {
              mergedAnswers.push(this.clean(answer));
            }
          })
          question.answers.forEach((answer) => {
            answer.content = this.clean(answer.content);
          })

          this.checkList[index] = mergedAnswers;
        })
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    handleAnswer(e) {
    },
    renderClassFill(checkList, index, answer, question) {
      if (!this.renderClass(answer, question)) {
        if (checkList[index].includes(this.clean(answer.content))) {
          return 'wrong-answer';
        }
      } else {
        return 'correct-answer';
      }
    },
    renderClass(answer, question) {
      let correctAnswers = question.correct_answer
        .split("##")
        .find((d) => this.clean(d) == this.clean(answer.content));
      if (correctAnswers) {
        return 'text-success';
      }
      return ''
    },
    clean(string) {
      string = string.replace(" ## ", "#").trim();
      string = string.replace(/[^\w\d ]/g, " ");
      string = string.replace("  ", " ").trim();
      string = string.toLowerCase();
      return string;
    }
  }
}
</script>

<style scoped></style>