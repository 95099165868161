<script>
import {E_NAME_TO_V_NAME} from "../../../../core/option/kidScore";

export default {
  name: "SelectOptionViewKid",
  props:{
    skillPoint:{
      type:Object,
      default(){
        return {}
      }
    }
  },
  methods:{
    translate(eName){
      return E_NAME_TO_V_NAME.get(eName)
    }
  }
}
</script>

<template>
  <div class="card">
    <div class="row px-3 py-2">
      <div class="col-3 d-flex align-items-center">
        <span class=" mb-0 bold " style="height: fit-content;font-size: 13px">{{translate(skillPoint?.key)}}</span>
      </div>
      <div class="col-9">
        <el-input style="white-space: pre-wrap"     :value="skillPoint?.value"/>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>