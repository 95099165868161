<template>
  <div class="card p-8" style="gap: 20px; background-color: #f6f4f8">
    <div class="p-5">
      <div v-for="(skillPoint,item) in point?.skillPoints" class="mb-3" :key="item">
        <select-option-view-kid v-if="isTestKid()" :skillPoint="skillPoint"></select-option-view-kid>
        <SelectOptionView v-else :skillPoint="skillPoint"></SelectOptionView>
      </div>
      <div class="d-flex">
        <div class="w-30 d-flex align-items-center">
          <span class="bold">Điểm toàn bài</span>
        </div>
        <el-input  disabled style="width: 30%; margin-left: 10px" :value="point.totalPoint" />
      </div>
    </div>
  </div>
</template>
<script>
import SelectOptionView from "@/views/pages/classrooms/components/SelectOptionView.vue";
import {CAMBRIDGE_MENTOR_ID} from "../../../../core/option/centerEDUTALK";
import SelectOptionViewKid from "./SelectOptionViewKid.vue";

export default {
  name: "TranscriptRecord",
  components: {SelectOptionViewKid, SelectOptionView},
  props:{
    point:{
      type:Object,
      default(){
        return {
          totalPoint: 0,
          skillPoints: []
        }
      }
    }
  },
  methods:{
    isTestKid() {
      return +this.$route.query.center_id === CAMBRIDGE_MENTOR_ID;
    }
  }
}
</script>

<style scoped>

</style>