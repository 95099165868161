<template>
  <div class="question">
    <homework-container :question="question"></homework-container>
    <div class="row">
      <div class="col-md-6" v-for="(item, index) in question.questions" :key="index">
        <div class="answer">
          <div class="answer-title">
            <div v-html="$options.filters.formatBrTagText(item.title)"></div>
            <el-image
                style="height: 95px"
                :src="item.image"
                v-if="item.image"
                fit="fill"
            ></el-image>
          </div>
          <div  v-for="(answer, key) in item.answers" :key="key">
            <el-radio-group v-model="radio[index]" :class="renderClassFill(radio, index, answer, item)">
              <!-- Xem kết quả -->
              <el-radio :label="answer.id" @change="handleAnswer" v-if="typeView === 'view-result'">
                <span :class="renderClass(answer, item)" >{{ answer.content }}</span>
              </el-radio>
              <!-- Xem bài tập -->
              <el-radio :label="answer.id" @change="handleAnswer" v-else>
                {{ answer.content }}
              </el-radio>
            </el-radio-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//css
import '@/assets/sass/homework/single-choice.scss';
import homeworkContainer from "@/views/pages/classrooms/homework-type/homework-container";
export default {
  name: "single-choice",
  components: {homeworkContainer},
  props: {
    question: {
      type: Object,
      default: () => { return {} }
    },
    typeView: {
      type: String,
      default: ''
    },
  },
  data(){
    return{
      radio: []
    }
  },
  watch: {
    question: {
      handler() {
          this.question.questions.forEach((question, index) => {
          let answer = question.answers.find((answer) => {
              return answer.content === question.student_answer
            }) || { id: '' }
            this.radio[index] = answer.id
          })
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    questions: function (){
      return this.question.questions
    },
  },
  methods: {
    handleAnswer(e){
    },
    renderClass(answer, question){
      if (answer.content === question.correct_answer){
        return 'text-success';
      }
      return ''
    },
    renderClassFill(radio, index, answer, question){
      if (this.typeView !== 'view-result') return ''
      if (!this.renderClass(answer, question)){
        if (radio[index] === answer.id){
          return 'wrong-answer';
        }
      }else{
        return 'correct-answer';
      }
    }
  }
}
</script>

<style>

</style>
<style scoped>
</style>