<template>
  <div class="question row">
    <div class="col-6">
      <homework-container :question="question"></homework-container>
      <div class="row">
        <div class="col-md-6" v-for="(item, index) in question.questions" :key="index">
          <div class="">
            <div class="answer-title mb-1">
              <div v-html="$options.filters.formatBrTagText(item.title)"></div>
            </div>
            <div v-if="typeView === 'view-result'">
              <div v-if="item.student_answer" class="bold mb-2">Bài làm của học viên:</div>
              <video v-if="item.student_answer" ref="video" controls preload="metadata" class="w-video">
                <source :src="appendSuffixUrl(item.student_answer)" type="video/mp4">
                <source :src="appendSuffixUrl(item.student_answer)" type="video/ogg">
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6" v-if="point">
      <!--      Bảng điểm các tiêu chí-->
      <TranscriptRecord :point="point"></TranscriptRecord>
    </div>
  </div>
</template>

<script>
import homeworkContainer from "@/views/pages/classrooms/homework-type/homework-container";
import TranscriptRecord from "@/views/pages/classrooms/components/TranscriptRecord.vue";
import {NameSkill, NameSkillOld} from "@/core/option/selectScore";
export default {
  name: "record-video-kid",
  components: {TranscriptRecord, homeworkContainer },
  props: {
    question: {
      type: Object,
      default: () => { return {} }
    },
    typeView: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      point:{}
    }
  },
  mounted() {
    this.point = {
      totalPoint: this.question?.outComes?.score,
      skillPoints: this.question?.outComes?.comments
    }
  },
  methods: {
    appendSuffixUrl(url) {
      return url + "#t=0.1";
    }
  }
}
</script>

<style scoped>
@media only screen and (max-width: 767px) {
  .w-video {
    width: 100%;
    object-fit: contain;
    height: 240px;
  }
}
@media only screen and (min-width: 768px) {
  .w-video {
    width: 375px;
    object-fit: contain;
    height: 280px;
  }
}
</style>