<template>
  <div class="question row">
    <div class="col-6">
      <homework-container :question="question"></homework-container>
      <div class="row">
        <div class="col-md-6" v-for="(item, index) in question.questions" :key="index">
          <div class="">
            <div class="answer-title mb-1">
              <div v-html="$options.filters.formatBrTagText(item.title)"></div>
            </div>
            <div class="" v-if="typeView === 'view-result'">
              <div class="bold mb-2">Bài làm của học viên:</div>
              <audio controls preload="metadata" ref="video-log">
                <source :src="item.student_answer" type="audio/ogg">
                <source :src="item.student_answer" type="audio/mpeg">
              </audio>
            </div>
            <div class="d-flex align-items-center" v-else>
              <el-button type="success" icon="el-icon-microphone" >Record Now</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6" v-if="point">
<!--      Bảng điểm các tiêu chí-->
      <TranscriptRecord :point="point"></TranscriptRecord>
    </div>
  </div>
</template>

<script>
import homeworkContainer from "@/views/pages/classrooms/homework-type/homework-container";
import TranscriptRecord from "@/views/pages/classrooms/components/TranscriptRecord.vue";
import {NameSkill, NameSkillOld} from "@/core/option/selectScore";


export default {
  name: "record-audio",
  components: {TranscriptRecord, homeworkContainer},
  props: {
    question: {
      type: Object,
      default: () => { return {} }
    },
    typeView: {
      type: String,
      default: ''
    },
  },
  mounted() {
    console.log(this.question);
    let outComes = this.question?.outComes;
    let skillPoints = outComes?.comments?.map((comment)=>{
       let key = Object.keys(comment)?.join('')
      let quantityComments = outComes?.comments?.length
      return {
        nameSkill: quantityComments === 4 ?NameSkill?.get(key):NameSkillOld.get(key),
        pointSkill:outComes?.score_detail[key],
        commentSkill:comment[key]
      }
    })
    this.point = {
      totalPoint : outComes?.score,
      skillPoints:skillPoints
    }
    },
  data(){
    return{
      point:{}
    }
  },
  methods: {}
}
</script>

<style scoped>

</style>
