<template>
  <div class="question row">
    <div class="col-6">
      <homework-container :question="question"></homework-container>
      <div class="row">
        <div class="col-md-12" v-for="(item, index) in question.questions" :key="index">
          <div class="">
            <div class="answer-title mb-1">
              <div v-html="$options.filters.formatBrTagText(item.title)"></div>
            </div>
            <div class="" v-if="typeView === 'view-result'">
              <div class="bold mb-2">Bài làm của học viên:</div>
              <div v-if="typeView === 'view-result'" class="w-100 card p-4" style="overflow: auto; max-height: 488px">{{question.questions[0].student_answer
                }}
              </div>
            </div>
            <div class="d-flex align-items-center" v-else>
              <el-button type="success" icon="el-icon-microphone">Record Now</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6" v-if="point">
      <!--      Bảng điểm các tiêu chí-->
      <TranscriptRecord :point="point"></TranscriptRecord>
    </div>
  </div>
</template>

<script>
import homeworkContainer from "@/views/pages/classrooms/homework-type/homework-container";
import TranscriptRecord from "@/views/pages/classrooms/components/TranscriptRecord.vue";
import {NameSkill, NameSkillOld} from "@/core/option/selectScore";


export default {
  name: "write-paragraph-kid",
  components: {TranscriptRecord, homeworkContainer},
  props: {
    question: {
      type: Object,
      default: () => {
        return {}
      }
    },
    typeView: {
      type: String,
      default: ''
    },
  },
  mounted() {
    this.point = {
      totalPoint: this.question?.outComes?.score,
      skillPoints: this.question?.outComes?.comments
    }
  },
  data() {
    return {
      point: {}
    }
  },
  methods: {}
}
</script>

<style scoped>

</style>
