<script>
export default {
  name: "SelectOptionView",
  props:{
    skillPoint:{
      type:Object,
      default(){
        return {}
      }
    }
  }
}
</script>

<template>
<div class="card">
  <div class="row px-3 py-2">
    <div class="col-3 d-flex align-items-center">
      <span class=" mb-0 bold " style="height: fit-content;font-size: 13px">{{skillPoint?.nameSkill}}</span>
    </div>
    <div class="col-3">
      <el-input  disabled :value="skillPoint?.pointSkill" />
    </div>
    <div class="col-6">
      <el-input type="textarea"  :autosize="{ minRows: 1, maxRows: 6 }" style="white-space: pre-wrap" :value="skillPoint?.commentSkill" class="custom-textarea" />
    </div>
  </div>
</div>
</template>

<style scoped>
.custom-textarea .el-textarea__inner {
  text-align: left !important;
}
</style>