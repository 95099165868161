<template>
  <div class="question">
    <homework-container :question="question"></homework-container>
    <div class="el-row">
      <div class="w-100">
        <div class="answer">
          <div class="answer-detail d-flex align-items-center">
            <!--    xem kết quả     -->
            <el-input type="textarea" :rows="3"
                      :value="question.questions[0].student_answer.replace(/-/g, ' ')"
                      :class="renderClass(question.questions[0])"
                      placeholder="Your answer..."
                      class="w-100"
                      v-if="typeView === 'view-result'"></el-input>
            <!--    xem câu hỏi     -->
            <el-input type="textarea" :rows="3"
                      placeholder="Your answer..."
                      class="w-100"
                      v-else></el-input>
          </div>
          <div class="d-flex justify-content-center mt-3 overflow-auto">
              <el-button v-for="(item, key) in question.questions[0].answers" :key="key" class="text-break btn-break"> {{ item.content }} </el-button>
          </div>
          <div class="el-row mt-3 d-flex justify-content-center overflow-auto" v-if="typeView === 'view-result'">
            <b class="">
              Correct answer:<span class="text-success"> {{ question.questions[0].correct_answer }}</span>
            </b>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import homeworkContainer from "@/views/pages/classrooms/homework-type/homework-container";
export default {
  name: "arrange-sentences",
  components: {homeworkContainer},
  props: {
    question: {
      type: Object,
      default: () => { return {} }
    },
    typeView: {
      type: String,
      default: ''
    },
  },
  data(){
    return{}
  },
  methods: {
    renderClass(question){
      let correctAnswers = question.correct_answer
        .split("##")
        .find((d) => this.clean(d) == this.clean(question.student_answer));
      if (correctAnswers){
        return 'correct-answer';
      }
      return 'wrong-answer'
    },
    clean (string) {
      string = string.replace(" ## ", "#").trim();
      string = string.replace(/[^\w\d ]/g, " ");
      string = string.replace("  ", " ").trim();
      string = string.toLowerCase();
      console.log("string",string);
      return string;
    }
  }
}
</script>

<style scoped>

</style>