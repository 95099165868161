import { render, staticRenderFns } from "./multi-choice.vue?vue&type=template&id=6be5e24a&scoped=true"
import script from "./multi-choice.vue?vue&type=script&lang=js"
export * from "./multi-choice.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6be5e24a",
  null
  
)

export default component.exports