<template>
  <div class="row">
    <div class="col-xl-12">
      <div id="panel-1" class="panel">
        <div v-if="homeworks.length > 0" class="panel-container show">
            <div v-if="typeView == 'view-result'" class="head-homework">
                <div class="row">
                    <div class="col-md-6 col-infor">
                        <div class="d-flex">
                            <span class="title-one-infor">Học viên: </span>
                            <b>{{ mark_detail.name }}</b>
                        </div>
                        <div class="d-flex">
                            <span class="title-one-infor">Số điện thoại:</span>
                            <b>{{ mark_detail.phone }}</b>
                        </div>
                        <div class="d-flex">
                            <span class="title-one-infor">Buổi học:</span>
                            <b>{{ lesson }}</b>
                        </div>
                    </div>
                    <div class="col-md-6 col-infor">
                        <div class="d-flex">
                            <span class="title-two-infor">Số câu đúng bài trắc nghiệm:</span>
                            <b>{{ mark_detail.test.score_auto_mark }}/{{ mark_detail.test.num_auto_mark }}</b>
                        </div>
                        <div class="d-flex">
                            <span class="title-two-infor">Số câu tự luận:</span>
                            <b>{{ esay_length }}</b>
                        </div>
                        <div class="d-flex">
                            <span class="title-two-infor">Thời gian nộp bài:</span>
                            <b>{{mark_detail.test.created_at | formatDateTimeVietnam}}</b>
                        </div>
                    </div>
                </div>
            </div>
          <div class="panel-content">
            <el-row v-loading="isloadingHomework" v-for="questions in  homeworks" :key="questions.id">
              <single-choice :question="questions"  v-if="questions.type === 'single_choice'" :type-view="typeView"></single-choice>
              <multi-choice :question="questions"  v-if="questions.type === 'multiple_choice'" :type-view="typeView"></multi-choice>
              <fill-text-audio :question="questions"  v-if="questions.type === 'fill_text_audio' || questions.type === 'fill_text_script'" :type-view="typeView"></fill-text-audio>
              <write-paragraph :question="questions" v-if="questions.type === 'write_paragraph' && !isTestKid()" :type-view="typeView"></write-paragraph>
              <write-paragraph-kid :question="questions" v-if="questions.type === 'write_paragraph' && isTestKid()" :type-view="typeView"></write-paragraph-kid>
              <record-audio :question="questions" v-if="questions.type === 'record_audio'&& !isTestKid()" :type-view="typeView"></record-audio>
              <record-audio-kid :question="questions" v-if="questions.type === 'record_audio' && isTestKid()" :type-view="typeView"></record-audio-kid>
              <record-video :question="questions" v-if="questions.type === 'record_video' && !isTestKid()" :type-view="typeView"></record-video>
              <record-video-kid :question="questions" v-if="questions.type === 'record_video'&& isTestKid()" :type-view="typeView"></record-video-kid>
              <match-text-image :question="questions" v-if="questions.type === 'match_text_image'" :type-view="typeView"></match-text-image>
              <arrange-sentences :question="questions" v-if="questions.type === 'arrange_sentences'" :type-view="typeView"></arrange-sentences>
            </el-row>
          </div>
          <div class="edutalk-paging">
            <div class="block">
              <el-pagination
                  background
                  @current-change="handleCurrentChange"
                  :current-page.sync="currentPage"
                  :page-size="1"
                  :pager-count="5"
                  layout="prev, pager, next"
                  :total="last_page">
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import singleChoice from "@/views/pages/classrooms/homework-type/single-choice";
import fillTextAudio from "@/views/pages/classrooms/homework-type/fill-text-audio";
import writeParagraph from "@/views/pages/classrooms/homework-type/write-paragraph";
import recordAudio from "@/views/pages/classrooms/homework-type/record-audio";
import recordVideo from "@/views/pages/classrooms/homework-type/record-video";
import matchTextImage from "@/views/pages/classrooms/homework-type/match-text-image";
import arrangeSentences from "@/views/pages/classrooms/homework-type/arrange-sentences";
import multiChoice from "@/views/pages/classrooms/homework-type/multi-choice";
import {
  GET_SCHEDULE_HOMEWORK, SHOW_HOMEWORK_RESULT, EXAM_LIST_MARK
} from "@/core/services/store/classes/classes.module";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {CAMBRIDGE_MENTOR_ID} from "../../../core/option/centerEDUTALK";
import WriteParagraphKid from "./homework-type/write-paragraph-kid.vue";
import RecordAudioKid from "./homework-type/record-audio-kid.vue";
import RecordVideoKid from "./homework-type/record-video-kid.vue";


export default {
  name: "homework-common",
  components: {
    RecordVideoKid,
    RecordAudioKid,
    WriteParagraphKid,
    singleChoice,
    fillTextAudio,
    writeParagraph,
    recordAudio,
    recordVideo,
    matchTextImage,
    arrangeSentences,
    multiChoice
  },
  data() {
    return{
      loading: false,
      currentPage: 1,
      isFilter: false,
      homeworks: [],
      last_page: 1,
      page: 1,
      classroom_id: null,
      learning_schedule_id: null,
      query: null,
      typeView: 'view-homework',
      isloadingHomework: true,
      student_id: null,
      lesson: 0,
      mark_detail: null,
      esay_length: 0,
    }
  },
  mounted() {
    let view_result = this.$route.query.type;
    this.lesson = this.$route.params.id;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: `Xem nội dung BTVN buổi ${this.lesson}`, icon: 'far fa-tasks' }
    ]);
    if (view_result) {
      this.typeView = "view-result";
      this.lesson = this.$route.query.lesson;
      this.$store.dispatch(SET_BREADCRUMB, [
      { title: `Xem kết quả bài tập về nhà buổi ${this.lesson}`, icon: 'far fa-tasks' }
    ]);
    }
    this.classroom_id = this.$route.query.classroom_id;
    this.student_id = this.$route.query.student_id;
    this.learning_schedule_id = this.$route.query.learning_schedule_id;
    this.getHomeWork();
  },
  methods: {
    handleCurrentChange(obj) {
      this.page = obj
      this.$router.push({
        name: 'content-homework',
        query: {
          page: this.page,
          ...this.query
        }
      })
      switch (this.typeView) {
        case 'view-homework':
          this.getContentHomeWork();
          break;
        case 'view-result':
          this.getResultHomeWork();
          break;
        default:
      }
    },
    getHomeWork() {
      switch (this.typeView) {
        case 'view-homework':
          this.getContentHomeWork();
          break;
        case 'view-result':
          this.getMarkStudent();
          this.getResultHomeWork();
          break;
        default:
      }
    },
    getContentHomeWork() {
      this.isloadingHomework = true;
      this.query = {
        classroom_id: this.classroom_id,
        learning_schedule_id: this.learning_schedule_id,
        center_id: this.$route.query.center_id
      }
      let payload = { ...this.query, page: this.page }
      this.$store.dispatch(GET_SCHEDULE_HOMEWORK,
        payload).then((res) => {
          if (!res.error) {
            this.homeworks = res.data.data
            this.last_page = res.data.last_page;
            this.isloadingHomework = false;
          }
        }).catch((e) => {
          console.log(e, 'error')
        });
    },
    getResultHomeWork() {
      this.isloadingHomework = true;
      this.query = {
        classroom_id: this.classroom_id,
        learning_schedule_id: this.learning_schedule_id,
        student_id: this.student_id,
        lesson: this.lesson,
        type: 'homework',
        center_id: this.$route.query.center_id
      }
      let payload = { ...this.query, page: this.page }
      this.$store.dispatch(SHOW_HOMEWORK_RESULT,
        payload).then((res) => {
          if (!res.error) {
            this.esay_length = res.data.count_esay;
            this.homeworks = res.data.test.data;
            this.last_page = res.data.test.last_page;
            this.isloadingHomework = false;

          }
        }).catch((e) => {
          console.log(e, 'error')
        });
    },
    getMarkStudent() {
      this.isloadingHomework = true;
      this.$store.dispatch(EXAM_LIST_MARK, { classroom_id: this.classroom_id, lesson: this.lesson, type: 'homework', user_id: this.student_id }).then((res) => {
        if (!res.error) {
          this.mark_detail = res.data[0];
          this.loadingMarkHomework = false;
        }
      }).catch((e) => {
        console.log(e, 'error')
      });
    },
    clickCallback(obj) {
      this.page = obj
      this.$router.push({
        name: 'content-homework',
        query: {
          page: this.page,
          ...this.query
        }
      })
      switch (this.typeView) {
        case 'view-homework':
          this.getContentHomeWork();
          break;
        case 'view-result':
          this.getResultHomeWork();
          break;
        default:
      }
    },
    isTestKid(){
      return +this.$route.query.center_id === CAMBRIDGE_MENTOR_ID
    }
  },
}
</script>

<style lang="scss" scoped>
.head-homework {
    width: 100%;
    height: auto;
    background: #FBF5FF;
    border-radius: 5px;
    margin-bottom: 20px;
    padding: 20px;
    font-size: 14px;
}
.col-infor:first-child {
    margin-bottom: 20px;
}

@media (min-width: 430px) {
.col-infor:first-child {
    margin-bottom: 0;
}
.col-infor {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100px;
}
.title-one-infor {
    display: block;
    width: 100px;
}
.title-two-infor {
    display: block;
    width: 200px;
}  
}
</style>